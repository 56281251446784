/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.size{
  width: 100%;
  height: 400px;
  background-color: red;
}

.img-size{
  width: 100%;
  height : 70vh;
}

.icon-font{
  color: red;
  font-size: 30px;
}

.prev-btn{
  width: 50px;
  height: 50px;
}

#carousel{
  /* min-height: 100vh; */
}

.set-padding{
  /* padding : 0; */
}